// extracted by mini-css-extract-plugin
export var alignLeft = "v_qh d_bG";
export var alignCenter = "v_bP d_bD";
export var alignRight = "v_qj d_bH";
export var textAlignLeft = "v_tw";
export var textAlignCenter = "v_tx";
export var textAlignRight = "v_ty";
export var hoursInnerWrapperAlt = "v_tz d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "v_tB d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "v_tC d_dw";
export var titleMargin = "v_tD d_cw";
export var hoursInnerInnerWrapper = "v_tF d_cz";