// extracted by mini-css-extract-plugin
export var alignLeft = "m_qh d_fp d_bG d_dv";
export var alignCenter = "m_bP d_fq d_bD d_dw";
export var alignRight = "m_qj d_fr d_bH d_dx";
export var FAQMainHeader = "m_kt d_kt d_w d_by";
export var FAQComponentHeader = "m_kz d_kz d_bx";
export var FAQComponentParagraph = "m_kv d_kv d_cD";
export var FAQComponentsWrapper = "m_kr d_kr d_cn d_cL d_cR d_c2 d_cX d_dB";
export var FAQComponentsWrapperClean = "m_ks d_ks d_cn d_cL d_cR d_c0 d_dB";
export var accordion__body = "m_kB d_kB d_by";
export var accordion__bodyHidden = "m_kD d_kD d_bw d_dl";
export var FAQDivider = "m_kq d_kq";
export var accordionWrapper = "m_qk d_cg";
export var accordion__item = "m_ql d_cL";
export var accordion_arrow = "m_kH d_kH";
export var accordion_arrow_open = "m_kK d_kK";
export var accordion_arrow_2 = "m_kM d_kM";
export var accordion_arrow_2_open = "m_kP d_kP";
export var Subtitle2Small = "m_qm q_qm q_qT q_q7";
export var Subtitle2Normal = "m_qn q_qn q_qT q_q8";
export var Subtitle2Large = "m_qp q_qp q_qT q_q9";